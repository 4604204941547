import img1 from "../assets/photos/photo-1588776813677-77aaf5595b83.jpeg";
import { useState } from "react";
import ContactForm from "../components/pages/ContactForm";
import styled from "styled-components";
import { device } from "./device";
import { Text } from "grommet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMicroscope,
  faTeeth,
  faSoap,
  faTooth,
  faFill,
  faXRay,
} from "@fortawesome/free-solid-svg-icons";
import ServiceBox from "../components/pages/ServiceBox";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  background-color: #f5faf0;
`;

export const PanelContainer = styled.div`
  display: flex;
  flex-direction: row;
  @media ${device.mobileM} {
    height: 100vh;
    display: flex;
  }
  @media ${device.tablet} {
    display: flex;
    flex-direction: column;
  }
`;

export const Nav = styled.div`
  @media ${device.laptopL} {
    justify-content: center;
  }

  @media ${device.mobileS} {
    font-size: 0.8rem;
  }

  .nav-text {
    margin-top: 3px;
  }

  @media ${device.tablet} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .euclid-text {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 2rem;
    }

    .nav-text {
      align-items: center;
      display: flex;

      @media ${device.mobileM} {
        a {
          font-size: 1rem;
        }
      }
    }
  }
  display: flex;
  margin-top: 2rem;
  width: 100%;
  justify-content: center;
  h1 {
    font-family: "Libre Baskerville", serif;
    display: initial;
    font-weight: 0;
    margin-right: 2rem;
  }
  a {
    letter-spacing: 2px;
    text-decoration: none;
    color: black;
    font-family: "Roboto", sans-serif;
    cursor: pointer;
    margin: 0 2rem;
    font-size: 1.5rem;
    font-weight: 10;

    &:hover {
      color: #3e9c87;
    }
  }
`;

export const BottomRight = styled.div`
  @media ${device.tablet} {
    display: none;
  }
  height: 50%;
  width: 100%;
  background-color: #fff;
`;

export const TopRight = styled.div`
  @media ${device.tablet} {
    display: none;
  }
  height: 30%;
  width: 100%;
  background-color: #3e9c87;
  border-radius: 15px 15px 0 0;
`;

export const LeftPanel = styled.div`
  margin-top: 1rem;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${device.tablet} {
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .services-container {
      display: flex;
      flex-direction: row;
    }

    .service-container {
      flex-direction: column !important;
    }
  }

  h1,
  h2,
  p {
    font-family: "Libre Baskerville", serif;
    display: initial;
    font-weight: 0;
    margin-bottom: 0 !important;
  }
  .dr-text {
    margin-top: 3rem;

    color: #bd943c;
    font-size: 5rem;
    @media ${device.px1304} {
      font-size: 3rem;
    }
    @media ${device.tablet} {
      color: #3e9c87;
      font-size: 2rem;
      margin-top: 0;
    }
    @media ${device.px588} {
      font-size: 1.6rem;
    }

    @media ${device.mobileM} {
      font-size: 1.3rem;
    }

    @media ${device.mobileS} {
      font-size: 1.15rem;
    }
  }

  .description-text {
    font-size: 2rem;
    color: #bd943c;

    @media ${device.px1304} {
      font-size: 1.75rem;
    }
    @media ${device.px588} {
      font-size: 1.4rem;
    }

    @media ${device.mobileM} {
      font-size: 1.15rem;
    }
    @media ${device.mobileS} {
      font-size: 1rem;
    }
  }
`;

export const Header = styled.div`
  @media ${device.mobileL} {
    display: none;
  }

  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  background-color: #3e9c87;
  top: 0;
  p {
    letter-spacing: 2px;
    font-family: "Roboto", sans-serif;
    color: white;
    font-weight: lighter;

    a {
      color: white;
      text-decoration: none;
      cursor: pointer;
      font-weight: 800;
      &:hover {
        text-decoration: underline;
      }
    }
  }
`;

const Services = () => {
  const [showForm, setShowForm] = useState(false);
  return (
    <Container showForm={showForm}>
      <Header>
        <p>
          <a onClick={() => setShowForm(true)}>FILL OUT</a> OUR SCHEDULING FORM
          TO SET UP AN APPOINTMENT TODAY
        </p>
      </Header>
      <Nav>
        <div className="euclid-text">
          <h1>Euclid Dental Center</h1>
        </div>
        <div className="nav-text">
          <a href="/"> Home </a>
          <a href="/about"> About </a>
          <a href="/services"> Services </a>
        </div>
      </Nav>
      <PanelContainer>
        <LeftPanel>
          <h1 className="dr-text">Our Services</h1>
          <div className="services-container">
            <div
              className="service-container"
              style={{ display: "flex", flexDirection: "row" }}
            >
              <ServiceBox
                description="Regular examinations are essential to promoting good oral health and dental health by detecting problems before they get worse, or preventing them all together."
                icon={faMicroscope}
                title="Oral Exams"
              />
              <ServiceBox
                description="A professional cleaning is performed in order to remove plaque and calculus from the teeth"
                icon={faSoap}
                title="Cleaning"
              />
              <ServiceBox
                description="A root canal is a procedure available to save a tooth that is infected and would otherwise require extraction. "
                icon={faTooth}
                title="Root Canal"
              />
            </div>

            <div
              className="service-container"
              style={{ display: "flex", flexDirection: "row" }}
            >
              <ServiceBox
                description="Treatment used to restore integrity and other damage using a strong mixture."
                icon={faFill}
                title="Filling"
              />
              <ServiceBox
                description="Prosthetic to replace missing or whole sets of teeth."
                icon={faTeeth}
                title="Dentures"
              />
              <ServiceBox
                description="X-Rays are taken to take a more thorough look at the teeth to identify cavities or other problems they could have."
                icon={faXRay}
                title="X-Rays"
              />
            </div>
          </div>
        </LeftPanel>
      </PanelContainer>
      {showForm && (
        <ContactForm
          closeModal={() => setShowForm(false)}
          isVisible={showForm}
        />
      )}
    </Container>
  );
};

export default Services;
