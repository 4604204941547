import styled from "styled-components";
import { device } from "./device";
import img1 from "../assets/photos/photo5.jpeg";
import { useState } from "react";
import ContactForm from "../components/pages/ContactForm";
import GoogleMapReact from "google-map-react";
import { Icon } from "@iconify/react";
import { Switch } from "antd";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  background-color: #f5faf0;
`;

export const PanelContainer = styled.div`
  display: flex;
  flex-direction: row;
  @media ${device.mobileM} {
    height: 100vh;
    display: flex;
  }
  @media ${device.tablet} {
    display: flex;
    flex-direction: column;
  }
`;

export const Nav = styled.div`
  @media ${device.laptopL} {
    justify-content: center;
  }

  @media ${device.mobileS} {
    font-size: 0.8rem;
  }

  @media ${device.tablet} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .euclid-text {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 2rem;
    }

    .nav-text {
      align-items: center;
      display: flex;

      @media ${device.mobileM} {
        a {
          font-size: 1rem;
        }
      }
    }
  }
  display: flex;
  align-items: center;
  margin-top: 2rem;
  width: 100%;
  h1 {
    font-family: "Libre Baskerville", serif;
    display: initial;
    font-weight: 0;
    margin-right: 2rem;
  }
  a {
    letter-spacing: 2px;
    text-decoration: none;
    color: black;
    font-family: "Roboto", sans-serif;
    cursor: pointer;
    margin: 0 2rem;
    font-size: 1.5rem;
    font-weight: 10;

    &:hover {
      color: #3e9c87;
    }
  }
`;

export const RightPanel = styled.div`
  overflow: hidden;
  margin-top: 1rem;
  margin-right: 1rem;
  align-items: center;
  flex-direction: column;
  display: flex;
  width: 50vw;
  @media ${device.tablet} {
    width: initial;
  }
  height: 100vh;
  img {
    position: absolute;
    @media ${device.tablet} {
      position: inherit;
      width: 85%;
    }

    @media ${device.mobileM} {
      margin-top: 5rem;
    }
    width: 45%;
    border-radius: 40%;
    top: 25%; //or whatever position from top
    left: 51.5%;
  }
  h1,
  h2 {
    margin-bottom: 0;
    font-family: "Libre Baskerville", serif;
    display: initial;
    font-weight: 0;
    margin-right: 2rem;
  }
`;

export const BottomRight = styled.div`
  @media ${device.tablet} {
    display: none;
  }
  height: 50%;
  width: 100%;
  background-color: #fff;
`;

export const TopRight = styled.div`
  @media ${device.tablet} {
    display: none;
  }
  height: 30%;
  width: 100%;
  background-color: #3e9c87;
  border-radius: 15px 15px 0 0;
`;

export const LeftPanel = styled.div`
  margin-top: 1rem;
  width: 50vw;
  display: flex;
  flex-direction: column;
  align-items: center;

  div {
    display: flex;
    flex-direction: column;
    margin: 0 2rem;
    align-items: center;
  }

  @media ${device.tablet} {
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  h1,
  h2,
  p {
    font-family: "Libre Baskerville", serif;
    display: initial;
    font-weight: 0;
    margin-bottom: 0 !important;
  }
  .dr-text {
    color: #bd943c;
    font-size: 5rem;
    @media ${device.px1304} {
      font-size: 3rem;
    }
    @media ${device.tablet} {
      color: #3e9c87;
      font-size: 2rem;
    }
    @media ${device.px588} {
      font-size: 1.6rem;
    }

    @media ${device.mobileM} {
      font-size: 1.3rem;
    }

    @media ${device.mobileS} {
      font-size: 1.15rem;
    }
  }

  .description-text {
    font-size: 2rem;
    color: #bd943c;

    @media ${device.px1304} {
      font-size: 1.75rem;
    }
    @media ${device.px588} {
      font-size: 1.4rem;
    }

    @media ${device.mobileM} {
      font-size: 1.15rem;
    }
    @media ${device.mobileS} {
      font-size: 1rem;
    }
  }
`;

export const Header = styled.div`
  @media ${device.mobileL} {
    display: none;
  }

  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  background-color: #3e9c87;
  top: 0;
  p {
    letter-spacing: 2px;
    font-family: "Roboto", sans-serif;
    color: white;
    font-weight: lighter;

    a {
      color: white;
      text-decoration: none;
      cursor: pointer;
      font-weight: 800;
      &:hover {
        text-decoration: underline;
      }
    }
  }
`;

const About = () => {
  const [showForm, setShowForm] = useState(false);
  return (
    <Container>
      <Header>
        <p>
          <a onClick={() => setShowForm(true)}>FILL OUT</a> OUR SCHEDULING FORM
          TO SET UP AN APPOINTMENT TODAY
        </p>
      </Header>
      <Nav style={{ justifyContent: "center", marginBottom: "2rem" }}>
        <div>
          <h1 className="euclid-text">Euclid Dental Center</h1>
        </div>
        <div className="nav-text">
          <a href="/"> Home </a>
          <a href="/about"> About </a>
          <a href="/services"> Services </a>
        </div>
      </Nav>
      <PanelContainer>
        <LeftPanel>
          <div>
            <p className="dr-text">About Dr. Francis Mceldowney</p>
            <p className="description-text">
              Dr. Mceldowney runs Euclid Dental Center located in Garden Grove,
              California. As a dentist who seeks to provide proper care to his
              patients, he's been dedicated to serving families to proper dental
              care for over 20 years.
            </p>
          </div>
          {/* <h2>Location</h2>
          <Map location={location} /> */}
        </LeftPanel>
        <RightPanel>
          <img src={img1} />
        </RightPanel>
      </PanelContainer>
      {showForm && (
        <ContactForm
          closeModal={() => setShowForm(false)}
          isVisible={showForm}
        />
      )}
    </Container>
  );
};

export default About;
