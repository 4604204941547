import logo from "./logo.svg";
import React from "react";
import "./assets/css/style.css";
import Home from "./pages/home";
import Services from "./pages/services";
import About from "./pages/about";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
function App() {
  return (
    <Router>
      <div className="app">
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/services" element={<Services />} />
          <Route exact path="/about" element={<About />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
