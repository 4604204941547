import styled from "styled-components";
import { Text } from "grommet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { device } from "../../pages/device";

export const Container = styled.div`
  height: 275px;
  width: 275px;
  background: white;
  margin: 10px;
  border-radius: 5px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;

  @media ${device.tablet} {
    height: 220px;
    width: 220px;
    .title-text {
      font-size: 15px !important;
    }

    .desc-text {
      font-size: 13px !important;
      line-height: 18px;
    }

    .icon-container {
      height: 45px !important;
      width: 45px !important;
    }
  }

  @media ${device.mobileM} {
    height: 150px;
    width: 160px;

    .text-container {
      margin-top: 0 !important;
    }

    .fa-2x {
      font-size: 1.25em;
    }

    .title-text {
      font-size: 10px !important;
    }

    .desc-text {
      font-size: 10px !important;
      line-height: 10px;
      margin-top: 0;
    }
    .icon-container {
      height: 30px !important;
      width: 30px !important;
      margin-bottom: 0 !important;
    }
  }

  @media ${device.mobileL} {
  }
`;

const ServiceBox = ({ icon, title, description }) => {
  return (
    <Container>
      <div
        className="icon-container"
        style={{
          margin: "1rem",
          width: "60px",
          height: "60px",
          background: "#3e9c87",
          borderRadius: "50%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <FontAwesomeIcon color="white" size="2x" icon={icon} />
      </div>
      <div
        className="text-container"
        style={{
          width: "100%",
          margin: "1rem",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Text className="title-text" style={{ fontSize: "1.5rem" }}>
          <b>{title}</b>
        </Text>
        <Text
          className="desc-text"
          color="#878787"
          margin={{ top: "5px", right: "1rem" }}
        >
          {description}
        </Text>
      </div>
    </Container>
  );
};

export default ServiceBox;
