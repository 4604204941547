import img1 from "../assets/photos/photo-1588776813677-77aaf5595b83.jpeg";
import {
  Container,
  Header,
  PanelContainer,
  LeftPanel,
  Nav,
  BookButton,
  RightPanel,
} from "./HomeComponents.styled";
import { useState, useRef } from "react";
import ContactForm from "../components/pages/ContactForm";

const Home = () => {
  const [showForm, setShowForm] = useState(false);
  return (
    <Container showForm={showForm}>
      <Header>
        <p>
          <a onClick={() => setShowForm(true)}>FILL OUT</a> OUR SCHEDULING FORM
          TO SET UP AN APPOINTMENT TODAY
        </p>
      </Header>
      <PanelContainer>
        <LeftPanel>
          <Nav>
            <div className="euclid-text">
              <h1>Euclid Dental Center</h1>
            </div>
            <div className="nav-text">
              <a> Home </a>
              <a href="/about"> About </a>
              <a href="/services"> Services </a>
            </div>
          </Nav>
          <h1 className="family-text">
            A family owned dentistry you will look forward to visting
          </h1>
          <div className="book-button-text">
            <BookButton onClick={() => setShowForm(true)}>Book Now</BookButton>
            <p className="call-text">
              OR CALL:{" "}
              <mark style={{ background: "none", color: "#2b7194" }}>
                <b>(714) 530-1039</b>
              </mark>
            </p>
          </div>
        </LeftPanel>
        <RightPanel>
          <img src={img1} />
        </RightPanel>
      </PanelContainer>
      {showForm && (
        <ContactForm
          closeModal={() => setShowForm(false)}
          isVisible={showForm}
        />
      )}
    </Container>
  );
};

export default Home;
