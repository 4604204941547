import emailjs from "emailjs-com";
import { Form, TimePicker, DatePicker, Modal, Input, Select } from "antd";
import { useEffect, useState, useRef } from "react";

const FormItem = Form.Item;
const Option = Select;

const ContactForm = ({ closeModal }) => {
  const [form] = Form.useForm();
  const [confirm, setConfirm] = useState(false);
  const disabledDate = (current) => {
    return current.day() === 3 || current.day() === 0;
  };

  const handleSubmit = (values) => {
    values.date = values?.date.format("dddd MM-DD-YYYY");
    values.time = values?.time.format("hh:mm A");
    emailjs
      .send(
        "service_rwr6cod",
        "template_wlhdewi",
        values,
        "user_SiX1q9QQoOqz0i5CwCAsn"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    setConfirm(true);
  };
  return (
    <>
      {confirm ? (
        <Modal
          onOk={() => closeModal()}
          visible
          cancelButtonProps={{ style: { display: "none" } }}
        >
          <p>Thank you for choosing Euclid Dental Center!</p>
          <p>
            We will contact you in the next 24 hours to confirm your
            appointment.
          </p>
        </Modal>
      ) : (
        <Modal
          title="Request Appointment"
          okText="Submit"
          visible
          onCancel={closeModal}
          //footer={null}
          onOk={() => {
            form
              .validateFields()
              .then((values) => {
                // form.resetFields();
                handleSubmit(values);
              })
              .catch((info) => {
                console.log("Validate Failed:", info);
              });
          }}
        >
          <Form
            form={form}
            onFinish={(e) => handleSubmit(e)}
            name="request-appointment-form"
            labelCol={{
              span: 8,
            }}
            wrapperCol={{
              span: 16,
            }}
          >
            <FormItem
              rules={[
                { required: true, message: "Please enter your first name" },
              ]}
              required
              name="first_name"
              label="First Name"
            >
              <Input />
            </FormItem>
            <FormItem
              rules={[
                { required: true, message: "Please enter your last name" },
              ]}
              required
              name="last_name"
              label="Last Name"
            >
              <Input />
            </FormItem>
            <FormItem
              rules={[
                { required: true, message: "Please enter your phone number" },
              ]}
              required
              name="phone_number"
              label="Phone Number"
            >
              <Input />
            </FormItem>
            <FormItem
              rules={[{ required: true, message: "Please enter your email" }]}
              required
              name="email"
              label="Email"
            >
              <Input />
            </FormItem>
            <FormItem
              rules={[
                {
                  required: true,
                  message: "Please select a requested service",
                },
              ]}
              required
              name="service"
              label="Requested Service"
            >
              <Select>
                <Option value="Exam">Exam</Option>
                <Option value="Cleaning">Cleaning</Option>
                <Option value="Root canal">Root canals</Option>
                <Option value="Fillings">Fillings</Option>
                <Option value="Crowns">Crowns</Option>
                <Option value="Dentures">Dentures</Option>
                <Option value="X-Rays">X-Rays</Option>
              </Select>
            </FormItem>
            <FormItem
              rules={[{ required: true, message: "Please select a time" }]}
              required
              name="time"
              label="Requested Time"
            >
              <TimePicker
                hideDisabledOptions
                use12Hours
                disabledHours={() => [0, 12]}
                minuteStep={30}
                format="hh:mm A"
              />
            </FormItem>
            <FormItem
              rules={[{ required: true, message: "Please select a date" }]}
              required
              name="date"
              label="Requested Date"
            >
              <DatePicker format="MM-DD-YYYY" disabledDate={disabledDate} />
            </FormItem>
          </Form>
        </Modal>
      )}
    </>
  );
};

export default ContactForm;
