const size = {
  mobileS: "405px",
  mobileM: "465px",
  mobileL: "644px",
  px588: "588px",
  tablet: "867px",
  laptop: "1024px",
  px1304: "1304px",
  laptopL: "1665px",
  desktop: "2560px",
};

export const device = {
  mobileS: `(max-width: ${size.mobileS})`,
  mobileM: `(max-width: ${size.mobileM})`,
  mobileL: `(max-width: ${size.mobileL})`,
  tablet: `(max-width: ${size.tablet})`,
  px588: `(max-width: ${size.px588})`,
  px1304: `(max-width: ${size.px1304})`,
  laptop: `(max-width: ${size.laptop})`,
  laptopL: `(max-width: ${size.laptopL})`,
  desktop: `(min-width: ${size.desktop})`,
  desktopL: `(min-width: ${size.desktop})`,
};
