import styled from "styled-components";
import { device } from "./device";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  background-color: #f5faf0;
`;

export const PanelContainer = styled.div`
  display: flex;
  flex-direction: row;
  @media ${device.mobileM} {
    height: 100vh;
    display: flex;
    margin-top: 10%;
  }
`;

export const RightPanel = styled.div`
  @media ${device.laptopL} {
    display: none;
  }
  overflow: hidden;
  width: 50vw;
  height: 90vh;
  img {
    max-width: 100%;
  }
`;

export const LeftPanel = styled.div`
  @media ${device.laptopL} {
    width: 100vw;
    display: flex;
    align-items: center;
  }

  @media ${device.mobileM} {
    margin: 0.25rem;
  }
  margin: 2rem;
  width: 50vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  .family-text {
    font-family: "Libre Baskerville", serif;
    font-size: 4rem;
    color: #bd943c;
    margin-top: 10%;
    @media ${device.laptopL} {
      text-align: center;

      width: 72vw;
    }
    @media ${device.tablet} {
      font-size: 3.5rem;
    }
    @media ${device.mobileL} {
      font-size: 2.5rem;
    }
    @media ${device.mobileM} {
      font-size: 1.75rem;
    }
  }

  .book-button-text {
    display: flex;
    align-items: center;
    flex-direction: row;
    @media ${device.laptopL} {
      justify-content: center;
      display: flex;
      flex-direction: column;
      > * {
        margin: 1rem;
      }
    }
  }

  .call-text {
    letter-spacing: 2px;
    font-family: "Roboto", sans-serif;
    margin-left: 1rem;
    font-size: 1.25rem;
    margin-bottom: 0;
  }
`;

export const Nav = styled.div`
  @media ${device.laptopL} {
    justify-content: center;
  }

  @media ${device.tablet} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .euclid-text {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 2rem;
    }

    .nav-text {
      align-items: center;
      display: flex;

      @media ${device.mobileM} {
        a {
          font-size: 1rem;
        }
      }
    }
  }

  @media ${device.mobileS} {
    .euclid-text {
      font-size: 0.8rem;
    }
  }
  display: flex;
  align-items: center;
  width: 100%;
  height: 10vh;
  h1 {
    font-family: "Libre Baskerville", serif;
    display: initial;
    font-weight: 0;
    margin-right: 2rem;
  }
  a {
    letter-spacing: 2px;
    text-decoration: none;
    color: black;
    font-family: "Roboto", sans-serif;
    cursor: pointer;
    margin: 0 2rem;
    font-size: 1.5rem;

    &:hover {
      color: #3e9c87;
    }
  }
`;

export const Header = styled.div`
  @media ${device.mobileL} {
    display: none;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  background-color: #3e9c87;
  top: 0;
  p {
    letter-spacing: 2px;
    font-family: "Roboto", sans-serif;
    color: white;
    font-weight: lighter;
    a {
      color: white;
      text-decoration: none;
      cursor: pointer;
      font-weight: 800;

      &:hover {
        text-decoration: underline;
      }
    }
  }
`;

export const BookButton = styled.button`
  @media ${device.laptopL} {
    width: 300px;
  }
  background-color: #3e9c87;
  color: white;
  border-radius: 5px;
  width: 35%;
  height: 55px;
  outline: 0;
  border-style: none;
  cursor: pointer;
  &:hover {
    background-color: #4bbda4;
  }
  font-size: 1.25rem;
`;
